<template>
  <div class="main-container-header">
    <div class="container-wrap">
      <div class="theme-img">
        <img class="the-img" src="@/assets/theme-complete.png" alt="">
      </div>
      <div class="title">
        {테마 상품 이용} 신청이 완료되었습니다
      </div>
      <div class="content">
        <span style="color:#ff6600;">테마 상품 신청 내역</span>에서 신청 현황을 확인할 수 있으며
        자세한 내용은 회원정보의 연락처와 이메일을 통해 안내됩니다
      </div>
      <div class="flex" style="margin-top: 45px; margin-bottom: 50px; justify-content: center">
        <button class="btn-primary">
          <a href="http://pg.launchpack.co.kr/service">홈으로 이동</a>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "IsNotLoginTheme",
        data(){
          return{

          }
        }
    }
</script>

<style scoped>
  .main-container-header{
    width: 100%;
    height: 100%;
  }

  .container-wrap{
    width: 100%;
  }
  .theme-img{
    width: 231px;
    height: 175px;
    /*border:1px solid #e6e6e6;*/
    margin:120px auto 22px auto;
    /*border-radius: 4px;*/
  }
  .the-img{

  }
  .title{
    font-size: 24px;
    text-align: center;
    font-weight: 500;
  }
  .content{
    font-size: 14px;
    text-align: center;
    white-space: pre-line;
    line-height: 28px;
    color:#606060;
  }

  button.btn-primary {
    width: 132px;
    height: 44px;
    border-radius: 3px;
    background-color: rgb(255, 102, 0);
    border: 1px solid #eaeaea;
    cursor: pointer;
    margin: 0 15px;
  }
  a{
    text-decoration: none;
    color: white;
    font-size: 14px;
    font-weight: bold;
  }
  @media (min-width: 320px) and (max-width: 400px){
    .title, .content{
      text-align: left;
      padding-left:7px;
    }
  }

</style>
